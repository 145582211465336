/* datepicker.css */

/* Style for the input field */
.react-datepicker-wrapper {
  display: inline-block;
  position: relative;
}

.react-datepicker__input-container {
  width: 100%;
}

.react-datepicker__input-container input {
  width: 100%;
  padding: 8px;
  font-size: 0.8rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Style for the calendar */
.react-datepicker {
  font-family: 'Arial', sans-serif;
}

.react-datepicker__header {
  background-color: #007bff;
  color: #fff;
}

.react-datepicker__day-names {
  display: flex;
  justify-content: space-around;
}

.react-datepicker__day-name, .react-datepicker__day {
  width: 2em;
  line-height: 2em;
  margin: 0.2em;
  border-radius: 4px;
}

.react-datepicker__day:hover {
  background-color: #007bff;
  color: #fff;
}

.react-datepicker__day--selected {
  background-color: #007bff;
  color: #fff;
}
