/* custom-select.css */

.custom-select {
    position: relative;
    display: inline-block;
    width: 150px;
  }
  
  .custom-select select {
    width: 100%;
    padding: 8px;
    font-size: 0.8rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    appearance: none; /* Remove default arrow in some browsers */
    cursor: pointer;
  }
  
  /* Styling the arrow icon */
  .custom-select::after {
    content: '\25BC'; /* Unicode character for down arrow */
    font-size: 16px;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none; /* Allow clicks to pass through the arrow to the select */
  }
  