.overflow-container {
    width: 300px; /* Set your desired width */
    height: 200px; /* Set your desired height */
    overflow: hidden; /* Hide the scroll bars */
    position: relative; /* Set position to allow absolute positioning */
  }
  
  .overflow-content {
    position: absolute; /* Position the content absolutely */
    top: 0;
    left: 0;
    width: 100%; /* Occupy full width */
    height: auto; /* Adjust height according to content */
    padding-right: 20px; /* Compensate for the hidden scrollbar space */
    /* You can adjust padding to avoid cutting off content */
    /* Other styles as needed */
  }
  .smaller-text {
    font-size: 12px; /* Adjust the font size to your preference */
  }